"use client";

import React from "react";
import style from "./../cateogry.module.scss";
import { FaTrashAlt } from "react-icons/fa";
import { SitesCategories } from "@/common/entities/sites/SiteCategories";
import { useSearchParams } from "next/navigation";
import { GroupedAttributesFilters } from "@/common/entities/product/Attributes";

interface CategoryProductsSearchFilterProps {
    filters: { [key: string]: string | undefined };
    onRemoveCharacteristicFilter: (characteristicId: string) => void;
    onRemoveIntervalFilter: (intervalId: string) => void;
    onRemoveParentCategoryFilter: (parentCategoryId: string) => void;
    attributesGroups?: GroupedAttributesFilters[];
    parentSiteCategories: SitesCategories[];
}

const CategoryProductsActiveFilters: React.FC<CategoryProductsSearchFilterProps> = ({
                                                                                        filters,
                                                                                        attributesGroups,
                                                                                        parentSiteCategories,
                                                                                        onRemoveCharacteristicFilter,
                                                                                        onRemoveIntervalFilter,
                                                                                        onRemoveParentCategoryFilter
                                                                                    }: CategoryProductsSearchFilterProps) => {
    if (!filters || Object.keys(filters).length === 0) {
        return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const searchParams = useSearchParams();
    const getAllActiveFilters = () => {
        const activeFilters: { id: number, name: string; type: "siteCategoryParentId" | "characteristic" | "interval" }[] = [];

        const siteCategoryParentId = searchParams.get("siteCategoryParentId");
        if (siteCategoryParentId && parentSiteCategories.length) {
            for (const siteCategory of parentSiteCategories) {
                if (siteCategory.id.toString() === siteCategoryParentId) {
                    activeFilters.push({ id: siteCategory.id, name: `${siteCategory.name ? siteCategory.name : siteCategory.category.name}`, type: "siteCategoryParentId" });
                }
            }
        }

        const characteristicsFilter = searchParams.get("characteristics");
        if (characteristicsFilter && attributesGroups?.length) {
            const characteristicsFilters = characteristicsFilter?.split(",");

            for (const characteristicFilter of characteristicsFilters) {
                for (const attributeGroup of attributesGroups) {
                    for (const char of attributeGroup.characteristics) {
                        if (char.id.toString() === characteristicFilter) {
                            activeFilters.push({ id: char.id, name: `${char.attributeName}: ${char.characteristicName}`, type: "characteristic" });
                        }
                    }
                }
            }
        }

        const intervalsFilter = searchParams.get("intervals");
        if (intervalsFilter && attributesGroups?.length) {
            const intervalsFilters = intervalsFilter?.split(",");

            for (const intervalFilter of intervalsFilters) {
                for (const attributeGroup of attributesGroups) {
                    if (attributeGroup?.intervals?.length) {
                        for (const interval of attributeGroup.intervals) {
                            if (interval.intervalId.toString() == intervalFilter) {
                                activeFilters.push({ id: interval.intervalId, name: `${attributeGroup.attributeName}: ${interval.intervalName}`, type: "interval" });
                            }
                        }
                    }
                }
            }
        }

        return activeFilters;
    };

    const onRemoveActiveFilter = (objectId: number, type: "siteCategoryParentId" | "characteristic" | "interval") => {
        switch (type) {
            case "siteCategoryParentId":
                onRemoveParentCategoryFilter(objectId.toString());
                return;
            case "characteristic":
                onRemoveCharacteristicFilter(objectId.toString());
                return;
            case "interval":
                onRemoveIntervalFilter(objectId.toString());
                return;
            default:
                break;
        }
    };

    return (
        <div className={style.cardFilter}>
            <ul className={style.listFilterSelected}>
                {getAllActiveFilters().map((activeFilter, idIndex) => (
                    <li key={`${activeFilter.name}-${idIndex}-${idIndex}`}>
                        <div className="d-flex align-items-center justify-content-between">
                            {activeFilter.name}
                            <FaTrashAlt
                                className="text-danger cursor-pointer"
                                size={19}
                                onClick={() => onRemoveActiveFilter(activeFilter.id, activeFilter.type)}
                                aria-label={`Remove filter ${activeFilter.name} with ID ${activeFilter.id}`}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CategoryProductsActiveFilters;
