"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col,  Offcanvas } from "react-bootstrap";
import style from "./../cateogry.module.scss";
import {
    getMainSiteCategoriesWithProducts,
    getProductsGroupedAttributes,
} from "@/services/ProductsService";
import { GroupedAttributesFilters } from "@/common/entities/product/Attributes";
import CategoryProductsActiveFilters from "@/components/Products/Filters/CategoryProductsActiveFilters";
import { usePathname, useSearchParams } from "next/navigation";
import LoadingFilters from "@/common/components/Loading/LoadingFilters";
import { SitesCategories } from "@/common/entities/sites/SiteCategories";
import SideFilters from "@/components/Products/Filters/SideFilters";

interface CategoryProductsFiltersProps {
    subCategoryUrl?: string;
    onRemoveCharacteristicFilter: (characteristicId: string) => void;
    onRemoveIntervalFilter: (intervalId: string) => void;
    onRemoveParentCategoryFilter: (parentCategoryId: string) => void;
    onCharacteristicsFilter: (characteristicId: number, filterable?: boolean) => void;
    onIntervalFilter: (intervalId: number, filterable?: boolean) => void;
    onParentSiteCategoriesFilter: (siteCategoryParentId: number, filterable?: boolean) => void;
    filters: { [key: string]: string | undefined };
    isAtPromotion?: boolean;
    isOutlet?: boolean;
    siteParentCategoriesFilterEnabled?: boolean;
    brandId?: number;
}

const ProductsSideFilters = ({
    subCategoryUrl,
    onRemoveIntervalFilter,
    onRemoveCharacteristicFilter,
     onRemoveParentCategoryFilter,
    onCharacteristicsFilter,
    onIntervalFilter,
    onParentSiteCategoriesFilter,
    filters,
    isAtPromotion,
    isOutlet,
    brandId,
                                 siteParentCategoriesFilterEnabled = false
}: CategoryProductsFiltersProps) => {
    const [showFilters, setShowFilters] = useState(false);
    const scrollTop = useRef<HTMLDivElement>(null);
    const [attributesGroups, setAttributesGroups] = useState<GroupedAttributesFilters[]>([]);
    const [siteParentCategories, setSiteParentCategories] = useState<SitesCategories[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const [, setSearchTerms] = useState<Record<string, string>>({});

    const fetchAttributes = useCallback(async () => {
        try {
            const resp = await getProductsGroupedAttributes({
                subCategoryUrl: subCategoryUrl && subCategoryUrl !== "promotii" || subCategoryUrl !== "outlet" ? subCategoryUrl : undefined,
                selectedCharacteristics: filters.characteristics,
                isAtPromotion,
                isOutlet,
                brandId: brandId ? brandId.toString() : undefined,
                siteCategoryParentId: filters.siteCategoryParentId,
                selectedIntervals: filters.intervals
            });

            const sortedAttributesGroups = resp.sort((a, b) => a.position - b.position);

            setAttributesGroups(sortedAttributesGroups);
            initializeSearchTerms(sortedAttributesGroups);
        } catch {
            setAttributesGroups([]);
        } finally {
            setIsLoading(false);
        }
    }, [subCategoryUrl, filters.characteristics, filters.intervals, isAtPromotion, isOutlet, filters.siteCategoryParentId]);

    const fetchParentCategories = async () => {
        const siteParentCategories = await getMainSiteCategoriesWithProducts({isAtPromotion, isOutlet, brandId: brandId ? brandId.toString() : undefined});
        if(siteParentCategories?.length) {
            setSiteParentCategories(siteParentCategories);
        }
    };

    useEffect(() => {
        fetchAttributes();
    }, [pathname, searchParams, fetchAttributes]);

    useEffect(() => {
        fetchParentCategories();
    }, []);

    const initializeSearchTerms = (groups: GroupedAttributesFilters[]) => {
        const initialSearchTerms = groups.reduce(
            (acc, group) => {
                acc[group.attributeName] = "";
                return acc;
            },
            {} as Record<string, string>,
        );
        setSearchTerms(initialSearchTerms);
    };

    const handleCloseOffcanvas = () => {
        setShowFilters(false);
        setTimeout(() => scrollTop.current?.scrollIntoView({ behavior: "smooth" }), 300);
    };


    if (isLoading) {
        return <LoadingFilters cards={3} />;
    }

    return (
        <>
            <div className="d-none d-md-block">
                <div className={style.filterTitle}>Filtreaza produse</div>
                <CategoryProductsActiveFilters
                    attributesGroups={attributesGroups}
                    filters={filters}
                    onRemoveCharacteristicFilter={onRemoveCharacteristicFilter}
                    onRemoveIntervalFilter={onRemoveIntervalFilter}
                    onRemoveParentCategoryFilter={onRemoveParentCategoryFilter}
                    parentSiteCategories={siteParentCategories}
                />
                <SideFilters
                    onCharacteristicsFilter={onCharacteristicsFilter}
                    filters={filters}
                    attributesGroups={attributesGroups}
                    onIntervalFilter={onIntervalFilter}
                    sitesCategoriesFilterEnabled={siteParentCategoriesFilterEnabled}
                    parentSiteCategories={siteParentCategories}
                    onParentCategoryFilter={onParentSiteCategoriesFilter}
                />
            </div>
            <Col className="d-md-none">
                <Button onClick={() => setShowFilters(true)} className="w-100 py-2 mt-3" variant="primary">
                    Filtreaza produse
                </Button>
            </Col>
            <Offcanvas show={showFilters} onHide={handleCloseOffcanvas} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filtreaza produse</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <CategoryProductsActiveFilters
                        attributesGroups={attributesGroups}
                        filters={filters}
                        onRemoveCharacteristicFilter={onRemoveCharacteristicFilter}
                        onRemoveIntervalFilter={onRemoveIntervalFilter}
                        onRemoveParentCategoryFilter={onRemoveParentCategoryFilter}
                        parentSiteCategories={siteParentCategories}
                    />
                    <SideFilters
                        onCharacteristicsFilter={onCharacteristicsFilter}
                        filters={filters}
                        attributesGroups={attributesGroups}
                        onIntervalFilter={onIntervalFilter}
                        sitesCategoriesFilterEnabled={siteParentCategoriesFilterEnabled}
                        parentSiteCategories={siteParentCategories}
                        onParentCategoryFilter={onParentSiteCategoriesFilter}
                    />
                    <Button onClick={handleCloseOffcanvas} className="w-100" variant="primary">
                        Vezi produse
                    </Button>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default ProductsSideFilters;
